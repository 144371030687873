<div
  [style.background]="backgroundColor"
  [style.color]="foregroundColor"
  class="task-status-chip"
>
  @switch (status) {
    @case (TaskStatus.Completed) {
      {{ 'conversations-v4.tasks.statuses.completed' | translate }}
    }
    @case (TaskStatus.NotStarted) {
      {{ 'conversations-v4.tasks.statuses.notStarted' | translate }}
    }
    @case (TaskStatus.InProgress) {
      {{ 'conversations-v4.tasks.statuses.inProgress' | translate }}
    }
    @case (TaskStatus.Closed) {
      {{ 'conversations-v4.tasks.statuses.closed' | translate }}
    }
    @case (TaskStatus.Deferred) {
      {{ 'conversations-v4.tasks.statuses.deferred' | translate }}
    }
    @case (TaskStatus.WaitingForConnection) {
      {{ 'conversations-v4.tasks.statuses.waitingForConnection' | translate }}
    }
    @case (TaskStatus.WaitingForApproval) {
      {{ 'conversations-v4.tasks.statuses.waitingForApproval' | translate }}
    }
  }
</div>
